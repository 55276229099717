import { Button, Container, Typography } from "@mui/material";
import lineup from "../../images/assets/LINEUP@300x.png";
import logomain from "../../images/assets/BPF@300x.png";
import infomain from "../../images/assets/Info.png";
import videoWebm from "../../videos/bpf.webm";
import videoMp4 from "../../videos/bpf.mp4";

function Home() {
  return (
    <div className="home">
      <Container maxWidth="xl">
        <div className="modal-content">
          <div className="lineup-container lineup-container-desktop">
            <img src={logomain} className="lineup-image" alt="logo" />
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>

          <div className="video-container">
            <video width="100%" autoPlay loop muted playsInline>
              <source src={videoWebm} type="video/webm" />

              <source src={videoMp4} type="video/mp4" />
            </video>
          </div>
          <img src={infomain} className="lineup-image" alt="info" />

          <Typography className="title" variant="h6">
            Tickets On Sale Now
          </Typography>
          <Typography className="text">Friday 29th August 2025</Typography>

          <Button
            href="https://www.seetickets.com/event/brighton-psych-fest-2025/various-brighton-venues/3146372"
            color="primary"
            size="large"
            variant="contained"
            className="ticket-header-button"
            sx={{
              fontSize: "26px",
              margin: "0",
              padding: "8px 70px",
              textAlign: "center",
            }}
            target="_blank"
          >
            BUY TICKETS
          </Button>
        </div>
      </Container>
    </div>
  );
}
export default Home;
