import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LineUpCard from "./line-up-card";

const artists = [
  {
    name: "Alphabet",
    sort: "Alphabet",
    link: "https://open.spotify.com/artist/6XcO14dMsawxjyAJOcmjM2?si=Mpk05MpXS5OjTOfm8kin2Q",
    img: "alphabet.jpg",
  },
  {
    name: "Adore",
    sort: "Adore",
    link: "https://open.spotify.com/artist/4BwY0FjNdkoWef8kixy54M?si=NNGCltTjShmT5bOgQTgjSA",
    img: "adore.jpeg",
  },
  {
    name: "Anna Erhard",
    sort: "Anna Erhard",
    link: "https://open.spotify.com/artist/11OTiI6xYGpLxS3aoVkI9e?si=4i7BZXWOSYSQAAJ4O10A8Q",
    img: "annaerhard.jpg",
  },
  {
    name: "Deadletter",
    sort: "Deadletter",
    link: "https://open.spotify.com/artist/4MfUQ99z2kgMnl9hAwffBx?si=OZEhSfrtQ8mKhXxiKBS3rQ",
    img: "deadletter.jpg",
  },
  {
    name: "Deary",
    sort: "Deary",
    link: "https://open.spotify.com/artist/5Pir3nnrulz7WMyC9bFhkL?si=ZEeXA38_Rx6wJ1F_NyN90Q",
    img: "deary.jpeg",
  },
  {
    name: "Du Blonde",
    sort: "Du Blonde",
    link: "https://www.dublonde.co.uk/",
    img: "dublonde.jpg",
  },
  {
    name: "Flip Top Head",
    sort: "Flip Top Head",
    link: "https://open.spotify.com/artist/3wvfYm6zG9B5o7eGY5zcLu?si=ViKMxER7TW-Or7k9gDcKMw",
    img: "flip.jpg",
  },
  {
    name: "GONG",
    sort: "GONG",
    link: "https://open.spotify.com/artist/47yvARr7dCOKqvjDVwfbf3?si=aoDgSkAsRgWobUC_p0_0GA",
    img: "gong.jpg",
  },
  {
    name: "Honeyglaze",
    sort: "Honeyglaze",
    link: "https://open.spotify.com/artist/0IJZjR1tj2EudGR8jvxZtM?si=GgLDz9N9TyiJcgvxtVTJ4g",
    img: "honeyglaze.jpg",
  },
  {
    name: "O.",
    sort: "O.",
    link: "https://open.spotify.com/artist/0R2UqQKBdSADXMN2Gx5CrB?si=F7vMAe7ERMCnwHr0iuljxQ",
    img: "o.jpg",
  },
  {
    name: "Crocodiles",
    sort: "Crocodiles",
    link: "https://open.spotify.com/artist/1Z4Erxt5wSXH0gbOsVJUMv?si=xW014GJcQNSmbggq2M7vRw",
    img: "crocodiles.jpg",
  },
  {
    name: "Bubble Tea & Cigarettes",
    sort: "Bubble Tea & Cigarettes",
    link: "https://open.spotify.com/artist/2UMeX51X1prCFgi51RHo9P?si=pRuM6x48T7q7xhH46lAfJA",
    img: "bubble.jpg",
  },
  {
    name: "Goblyns",
    sort: "Goblyns",
    link: "https://open.spotify.com/artist/4FKI84FE62a5BxfrKtB1HF?si=bJvffvyaRnuzTMWP5o4u1A",
    img: "goblyns.jpg",
  },
  {
    name: "Mojo & The Kitchen Brothers",
    sort: "Mojo & The Kitchen Brothers",
    link: "https://open.spotify.com/artist/0uK9AvSTGcy7LKeEGjVvl4?si=cSirBpW8S1CF-Dxkn7_Fnw",
    img: "mojo.jpeg",
  },
  {
    name: "Blind Yeo",
    sort: "Blind Yeo",
    link: "https://open.spotify.com/artist/5wf9NEx8BtntEd3HiqLNjd?si=p9UzlfuZT5iDNEuvzb3g3w",
    img: "blindyeo.jpg",
  },

  {
    name: "Gruff Rhys",
    sort: "Gruff Rhys",
    link: "https://gruffrhys.com/",
    img: "gruff.jpg",
  },
  {
    name: "Jane Weaver",
    sort: "Jane Weaver",
    link: "https://open.spotify.com/artist/1UrfmsMDKHSYXj7SaPjyWL?si=4JjkE-dcQkOm-hBU_cmzlw",
    img: "janeweaver.jpg",
  },
  {
    name: "Getdown Services",
    sort: "Getdown Services",
    link: "https://open.spotify.com/artist/4OTD2AbOu5iBqSWk3NfwG5?si=l19cmq7qStiZgYrb9UGKiQ",
    img: "getdownserv.jpg",
  },
  {
    name: "La Sécurité",
    sort: "La Sécurité",
    link: "https://open.spotify.com/artist/5ijO3JnnKpoAOTU4QaDgdW?si=b94LbTZyQsC_8F1-De2eyw",
    img: "lasec.jpg",
  },
  {
    name: "Opus Kink",
    sort: "Opus Kink",
    link: "https://www.instagram.com/opuskink?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    img: "opus.jpeg",
  },
  {
    name: "Rabbitfoot",
    sort: "Rabbitfoot",
    link: "https://www.instagram.com/rabbitfoottheband/",
    img: "rabbitfoot.png",
  },
  {
    name: "YIN YIN",
    sort: "YIN YIN",
    link: "https://www.instagram.com/yinyinband?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    img: "yinyin.jpg",
  },
  {
    name: "Mandrake Handshake",
    sort: "Mandrake Handshake",
    link: "https://www.instagram.com/mandrakehandshake?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    img: "mandrake.jpg",
  },
  {
    name: "Pyncher",
    sort: "Pyncher",
    link: "https://www.instagram.com/pyncher?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    img: "pyncher.jpeg",
  },
  {
    name: "GOODBYE",
    sort: "GOODBYE",
    link: "https://www.instagram.com/goodbye.band?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    img: "goodbye.jpg",
  },
];

const sortedArtists = artists.sort((a, b) => a.sort.localeCompare(b.sort));

function LineUp() {
  return (
    <Container className="LineUp" maxWidth="xl">
      <Box sx={{ flexGrow: 1, flexWrap: "wrap", display: { xs: "flex" } }}>
        {sortedArtists.map((artist) => (
          <LineUpCard key={artist.name} artist={artist} />
        ))}
      </Box>
    </Container>
  );
}
export default LineUp;
